<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { nanoid } from 'nanoid';
import { onBeforeRouteLeave } from 'vue-router';

import api from '@/services/api';
import {
  AppLoader,
  AppButton,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  ConfirmModal,
  AppBox,
  AppBoxBody,
  HelpInformation,
  AppTableBody,
} from '@/components';
import useTrackChanges from '@/composables/useTrackChanges';
import useLoader from '@/composables/useLoader';
import {
  ICustomDayDurationRequestResource,
  ICustomDayDurationsListResource,
  ICustomDayDurationsRequestResource,
} from '@/types/BusinessDay';
import { useTitle } from '@vueuse/core';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const cancelLoader = useLoader({ useProgress: false });
const saveLoader = useLoader({ useProgress: false });

const form = reactive<ICustomDayDurationsRequestResource>({
  default_working_hours: 8,
  custom_day_duration: [],
});
const tracker = useTrackChanges(form);

async function getDayDurations() {
  try {
    const response = await api.businessDay.list();
    form.custom_day_duration = response.data.custom_day_durations.map((day) => ({ ...day, uid: nanoid() }));
    pushFirstElement(response.data);
    tracker.commit();
  } catch (error) {
    console.error(error);
  }
}

function onDelete(dayDuration: ICustomDayDurationRequestResource) {
  if ((!dayDuration.hours || !dayDuration.hours) && dayDuration.months.length === 0) {
    form.custom_day_duration = form.custom_day_duration.filter((item) => item.uid !== dayDuration.uid);
  } else {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('business-day.confirm.destroy.title'),
        message:
          dayDuration.months.length > 0
            ? t('business-day.confirm.destroy.textIfIssetMonths')
            : t('business-day.confirm.destroy.text', {
                hours: dayDuration.hours,
              }),
        onConfirm() {
          form.custom_day_duration = form.custom_day_duration.filter((item) => item.uid !== dayDuration.uid);
          close();
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  }
}

function addDayDuration() {
  form.custom_day_duration.push({
    uid: nanoid(),
    hours: null,
    months: [],
  });
}

async function cancelChanges() {
  cancelLoader.start();
  try {
    await getDayDurations();
  } catch (error) {
    console.error(error);
  } finally {
    cancelLoader.finish();
  }
}

async function submit() {
  saveLoader.start();
  try {
    const response = await api.businessDay.store({
      default_working_hours: form.custom_day_duration[0].hours,
      custom_day_duration: form.custom_day_duration.slice(1),
    });
    form.custom_day_duration = response.data.custom_day_durations.map((day) => ({ ...day, uid: nanoid() }));
    pushFirstElement(response.data);
    tracker.commit();
  } catch (error) {
    console.error(error);
  } finally {
    saveLoader.finish();
  }
}

function onMonthCheck(event: Event, dayDuration: ICustomDayDurationRequestResource, month: number) {
  form.custom_day_duration.forEach((day) => {
    day.months = day.months.filter((m) => m !== month);
  });
  dayDuration.months.push(month);
}

function pushFirstElement(list: ICustomDayDurationsListResource) {
  const months = Array.from({ length: 12 }, (x, i) => i + 1);
  form.custom_day_duration.forEach((day) => {
    day.months.map((month) => {
      const index = months.indexOf(month);
      if (index !== -1) {
        months.splice(index, 1);
      }
    });
  });

  form.custom_day_duration.unshift({
    uid: nanoid(),
    hours: list.default_working_hours,
    months: months,
  });
}

onMounted(async () => {
  loader.start();
  await getDayDurations();
  loader.finish();
});

function onBeforeUnload(event: BeforeUnloadEvent) {
  if (!tracker.isModified.value) return true;
  event.preventDefault();
  const customMessage = t('common.confirms.unsaved.title');
  event.returnValue = customMessage;
  return customMessage;
}

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});

onBeforeRouteLeave((_to, _from, next) => {
  if (tracker.isModified.value) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('common.confirms.unsaved.title'),
        message: t('common.confirms.unsaved.text'),
        onConfirm() {
          next();
          close();
        },
        onCancel() {
          next(false);
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  } else {
    next();
  }
});

const title = useTitle(computed(() => t('business-day.index.title')));
</script>

<template>
  <div v-show="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-show="!loader.isLoading.value" class="container-wide">
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="business-day.index.help" />
    </div>
    <form class="mt-3">
      <AppTable hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('business-day.attributes.work_day_durations') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.january') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.february') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.march') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.april') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.may') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.june') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.july') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.august') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.september') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.october') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.november') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('business-day.attributes.december') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="(dayDuration, index) in form.custom_day_duration" :key="dayDuration.uid">
            <AppTableTd>
              <div class="form-wrapper is-small has-icon">
                <input
                  type="number"
                  v-model.number="dayDuration.hours"
                  class="form-control"
                  :min="1"
                  :max="24"
                  placeholder="0"
                />
                <i class="form-icon ti ti-clock" />
              </div>
            </AppTableTd>
            <AppTableTd v-for="month in 12" :key="month">
              <label class="form-radio">
                <input
                  type="checkbox"
                  :value="month"
                  v-model="dayDuration.months"
                  @change="onMonthCheck($event, dayDuration, month)"
                />
                <span class="checkmark"></span>
              </label>
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                @click.stop.prevent="onDelete(dayDuration)"
                v-if="index !== 0"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
                v-tooltip.left="t('common.delete')"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppBox class="mt-1">
        <AppBoxBody>
          <AppButton
            v-if="form.custom_day_duration.length < 12"
            v-tooltip.right="t('common.add')"
            @click.prevent="addDayDuration"
            color="success"
            circle
            light
          >
            <FontIcon name="plus" />
          </AppButton>
          <div class="mt-4 d-flex">
            <AppButton @click.prevent="cancelChanges" light :loading="cancelLoader.isLoading.value">
              {{ t('common.cancel') }}
            </AppButton>
            <AppButton
              :disabled="!tracker.isModified.value"
              color="success"
              class="ml-auto"
              @click.prevent="submit"
              :loading="saveLoader.isLoading.value"
            >
              {{ t('common.save') }}
            </AppButton>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
  </div>
</template>
