<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { ClientHeader, ClientNavigation } from '@/components';
import useClientStore from '@/store/ClientStore';
import ClientFiles from '@/components/Clients/ClientFiles.vue';
import { useTitle } from '@vueuse/core';
import { computed, onMounted, watch } from 'vue';
import useClients from '@/composables/useClients';
import useAuthStore from '@/store/AuthStore';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const route = useRoute();
const router = useRouter();
const { isEmployeeRole } = useAuthStore();
const clientStore = useClientStore();
const { client, isUserPartOfTeam } = storeToRefs(clientStore);
const { clientTabs } = useClients();

useTitle(
  computed(() => {
    const activeTab = clientTabs.value.find((tab) => tab.route === route.name);
    if (activeTab) {
      return `${client.value.name}: ${activeTab.title}`;
    }
    return client.value.name;
  }),
);
watch(
  () => route.name,
  () => {
    document.getElementById('content')?.scrollTo({ top: 0, behavior: 'smooth' });
    checkAbilityToSeeFiles();
  },
);

function checkAbilityToSeeFiles() {
  if (route.name === 'client.files' && isEmployeeRole && !isUserPartOfTeam.value) {
    router.replace({ name: 'client.overview', params: { uuid } });
  }
}

onMounted(checkAbilityToSeeFiles);
</script>
<template>
  <div class="container-fluid px-md-5">
    <ClientHeader :client="client" />
    <ClientNavigation :client="client" :is-user-part-of-team="isUserPartOfTeam" />
    <ClientFiles :visibility="route.name === 'client.files'" :uuid="uuid" />
    <RouterView v-slot="{ Component }">
      <component :is="Component" />
    </RouterView>
  </div>
</template>
