import api from '@/services/api';
import { ref } from 'vue';
import { IUserListResource } from '@/types/User';
import { Options } from 'ky';

export default function useUsers() {
  const users = ref<IUserListResource[]>([]);
  const usersLoading = ref(false);

  async function getUsers(options?: Options) {
    try {
      usersLoading.value = true;
      const response = await api.users.list(options);
      users.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      usersLoading.value = false;
    }
  }

  return {
    users,
    usersLoading,
    getUsers,
  };
}
