export default {
  index: {
    title: 'Projects',
    help: '',
    remove: 'Delete project',
    complete: 'Complete project',
    cancel: 'Cancel project',
    rollover: 'Project rollover',
    reactivate: 'Reactivate project',
    filters: {
      name_or_client: 'Project name or client name',
      name: 'Project name',
    },
    create_modal: {
      title: 'Please select the client for which you want to create a project',
      name: 'Client name',
    },
  },
  create: {
    title: 'Plan new project for {name}',
    help: '',
  },
  edit: {
    title: 'Edit project {name}',
    help: '',
  },

  empty: 'No projects',

  step: {
    service: 'Service',
    price: 'Price',
    team: 'Team',
    planning: 'Planning',
    activate: 'Summary',
  },

  // Attributes
  attributes: {
    name: 'Project name',
    status: 'Status',
    client: 'Client',
    user: 'Project responsible',
    service: 'Service',
    financial_year: 'Fiscal year',
    start_date: 'Project start date',
    end_date: 'Project end date',
    start_end_date: 'Start / End date',
    start_and_end_date: 'Project start & Project end date',
    deadline_date: 'Project deadline date',
    price_type: 'Price type',
    billing_frequency: 'Billing frequency',
    annual_price: 'Project fee',
    only_invoice_part_of_year: 'Only invoice part of year',
    number_of_months_for_invoices_per_part_of_year: 'Number of months',
    estimating_price: "This year's fee",
    discount: 'Discount %',
    this_year_fee: "This year's fee",
    estimating_annual_fee: 'Estimated project fee',
    tasks_in_portal: 'Tasks in portal',
    other_team_members: 'Other team members',
  },
  placeholders: {
    name: 'Enter name',
  },

  invoice_schedule: 'Invoice schedule',

  price: {
    fixed: 'Fixed',
    hourly: 'Hourly',
  },

  billing_frequency: {
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    'semi-annually': 'Semi-annually',
    annually: 'Annually',
  },

  add_member_title: 'Select employee and project role to add',
  team_member_role: {
    team_member: 'Team member',
    responsible: 'Project responsible',
  },
  project_role: 'Project role',

  planning_table: {
    summary: 'Summary',
    total_time: 'Total time',
    total_price: 'Total fee',
    margin: 'Margin %',
    this_project: 'This project',
    next_project: 'Next project',
    hour: 'h',
  },

  tasks_table: {
    name: 'Task',
    frequency: 'Frequency',
    week: 'Week',
    deadline: 'Deadline',
    estimated_time: 'Estimated time',
    team_member: 'Team member',
    charge_separately: 'Charge separately',
    price_type: 'Price type',
    sub_label: 'Sublabel',
    amount: 'Amount, SEK',
    discount: 'Discount, %',
    add_label_on_tasks: 'Add sublabel on tasks',
    restore_plan_from_default: 'Restore plan from default',
  },

  task_details_table: {
    title: 'Task details',
    task: 'Task',
    frequency: 'Frequency',
    week: 'Week',
    deadline: 'Deadline',
    est_time: 'Est time',
    team_member: 'Team member',
    price_info: 'Price info',
    production_cost: 'Production cost',
    total_this_project: 'Total this project',
    total_next_project: 'Total next project',
  },

  fee_and_invoicing_table: {
    title: 'Fee & Invoicing',
    price_type: 'Price type',
    invoice_frequency: 'Invoice frequency',
    discount: 'Discount',
  },

  invoices_table: {
    title: 'Invoices',
    amount: 'Amount',
    fee_type: 'Fee type',
    month: 'Month',
  },
  project_earnings_estimate_title: {
    title: 'Project earnings estimate',
    first_year: 'This project',
    next_year: 'Next project',
    annual_price: 'Annual price',
    annual_hours: 'Annual hours',
    revenue_hour: 'Revenue/Hour',
    production_cost: 'Production cost',
    margin: 'Margin',
    margin_percent: 'Margin %',
  },

  statuses: {
    all: 'All',
    active: 'Active',
    draft: 'Draft',
    done: 'Done',
    cancelled: 'Cancelled',
    deleted: 'Deleted',
  },

  active_project: 'Activate project',
  base_price: 'Base fee',
  separate_price: 'Separate fee',
  estimated_hourly_fee: 'Hourly fee (estimated)',

  summaries: {
    invoiced: 'Invoiced',
    scheduled: 'Scheduled',
    fee_summary: 'Fee summary',
    difference: 'Difference',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" project',
    destroy: 'Delete "{name}" project',
    view: 'View "{name}" project',
    plan: 'Plan "{name}" project',
    cancel: 'Cancel "{name}" project',
    rollover: 'Rollover "{name}" project',
    reactivate: 'Reactivate "{name}" project',
    copy_next_week: 'Copy selected week to next task',
    copy_next_weeks: 'Copy selected week to all future tasks',
    show_events: 'Show events',
    hide_events: 'Hide events',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete project',
      text: 'Are you sure you want to delete <strong>{name}</strong> project from the system?',
    },
    restore_default_tasks: {
      title: 'Restore default tasks',
      text: 'Are you sure you want to restore default tasks?',
    },
  },

  messages: {
    the_fee_summary_does_not_match: {
      title: 'The fee summary does not match the project fee.',
      text: 'Update scheduled fees or project fee?',
    },
    project_dates_updated: {
      title: 'Project dates have been updated.',
      text: 'You may need to change scheduled invoices and tasks. Continue with the planning of the project to make any necessary consequential changes.',
    },
    project_has_been_activated: {
      text: 'Project has been created/updated and tasks have been scheduled.',
    },
  },

  view: {
    overview: 'Overview',
    status: 'Status',
    deadline: 'Deadline',
    none: 'None',
    tasks: 'Tasks',
    comment: 'Comment',
    there_are_unfinished_tasks:
      'There are scheduled tasks that are not finished. Please review before closing the project.',
    there_are_no_unfinished_tasks: 'All tasks are completed',
    cancel_all_open_tasks: 'Cancel all open tasks',
    all_tasks_must_be_completed_to_close_project: 'All project tasks must be completed to close the project',
  },

  cancel_modal: {
    title: 'Close project {name}',
    message:
      'By clicking "Confirm", the project will be closed and inactivated. You can reactivate project later if you need.',
    warning:
      'The project has tasks that have not been started or completed. By closing the project, started tasks will be marked as done, and unstarted tasks will be removed from the task list and cannot be reported time on.',
    start_new_project: 'Start planning a new project by copying the plan for project {name}',
    increase_prices: 'Increase any fixed prices automatically',
    percentage_price_increase: 'Enter the percentual price increase for fixed prices ',
  },
  reactivate_modal: {
    title: 'Reactivate project {name}',
    message: '',
  },
  rollover_modal: {
    title: 'Project rollover {name}',
  },
};
